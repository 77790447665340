<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="id"
      >
        <a-input
          v-decorator="['id',{ initialValue:'0' }]"
          name="id"></a-input>
      </a-form-item>
      <a-form-item
        label="姓名"
        :required="true"
      >
        <a-input
          v-decorator="[
            'trueName',
            {rules: [{ required: true, message: '请输入姓名' }]}
          ]"
          name="trueName"
          placeholder="请输入姓名"/>
      </a-form-item>

      <a-form-item
        label="Email"
      >
        <a-input
          v-decorator="[
            'email',
            {rules: [{ required: true, message: '请输入Email' }]}
          ]"
          name="email"
          placeholder="请输入Email"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="类型"

      >
        <a-radio-group v-decorator="['type', { initialValue: 1 }]" name="type">
          <a-radio :value="1">预约</a-radio>
          <a-radio :value="2">门诊</a-radio>
          <a-radio :value="3">住院</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { allList } from '@/api/channel'
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this),
        channeldata: [],
        channelshow: false
      }
    },
    created () {
      this.getAllList({})
    },
    methods: {
      getAllList (params) {
        allList(params).then(res => {
          this.channeldata = res.result
          this.$forceUpdate()
        })
      },
      changeType (val) {
        console.log(val)
        this.channelshowValue(val.target.value)
        this.form.setFieldsValue({
          channelId: undefined
        })
      },
      channelshowValue (val) {
        if (val === 1) {
          this.channelshow = false
        } else {
          this.channelshow = true
        }
      }
    }
  }
</script>
